<template>
	<section class="sect_contacts" id="contacts" style="background-color: #fff;">
		<v-container>
			<v-row>
				<div class="mr-auto ml-auto contact">
					<h2 class="text-h3 text-center font-weight-bold mb-3">Контакты</h2>
					<p class="text-center font-weight-thin text-h6 text-sm-h5 text-md-h4">Режим работы: ежедневно с 06.00 до 03.00.</p>
					<p class="text-center font-weight-thin text-h6 text-sm-h5 text-md-h4 mb-3">Наш единый контактный номер:</p>
					<a href="tel:+79095066777" class="text-center font-weight-regular text-h4 text-decoration-none d-block text-black">+7 909 506 67 77</a>
					<iframe src="https://yandex.ru/sprav/widget/rating-badge/145952256912?type=rating" width="150" height="50" class="ml-auto mr-auto d-block mt-16" frameborder="0"></iframe>
				</div>
			</v-row>
		</v-container>
	</section>
</template>

<style lang="sass" scoped>
.contact
	margin-top: 20%
	margin-bottom: 20%
</style>
